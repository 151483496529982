<template>
  <v-dialog
    v-model="dialogRelatorios"
    @click:outside="$emit('update:dialogRelatorios', false)"
    @keydown.esc="$emit('update:dialogRelatorios', false)"
    max-width="900px"
    scrollable
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.relatorio", 2) }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogRelatorios', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <!-- Pai -->
          <v-col
            cols="12"
            md="5"
            class="box-esquerda"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                : ''
            "
          >
            <v-list class="" nav>
              <v-list-item-group color="tocs_blue">
                <template v-for="(menu, index) in menus">
                  <v-list-item
                    v-if="menu.active"
                    class="mb-0"
                    :key="index"
                    @click="selectedMenu = menu"
                  >
                    <v-list-item-icon>
                      <v-icon size="20">{{ menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ menu.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    v-if="index != menus.length - 1 && menu.active"
                    class="my-1"
                    :key="index + '-a'"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>

          <!-- Filho -->
          <v-col
            cols="12"
            md="7"
            :class="{
              'd-flex align-center justify-center': !selectedMenu,
              'box-direita': selectedMenu,
            }"
          >
            <div v-if="!selectedMenu" class="text-center text-subtitle-1">
              Por favor, selecione um item <br />
              para busca um relatório!
            </div>

            <div v-if="selectedMenu && selectedMenu.items.length">
              <v-list nav>
                <v-list-item-group color="tocs_blue">
                  <template v-for="(item, index) in selectedMenu.items">
                    <v-list-item
                      v-if="item.type == 'single'"
                      class="mb-0"
                      :key="index"
                      @click="openDialogFiltrosRelatorio(item)"
                    >
                      <v-list-item-icon>
                        <v-icon size="20">mdi-file-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                      v-if="item.type == 'group'"
                      :value="false"
                      :key="index"
                      no-action
                      prepend-icon="mdi-chevron-down"
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <template v-for="(itemgroup, indexgroup) in item.items">
                        <v-list-item
                          :key="indexgroup"
                          class="mb-0"
                          @click="openDialogFiltrosRelatorio(itemgroup)"
                        >
                          <v-list-item-icon>
                            <v-icon size="20">mdi-file-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ itemgroup.title }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-group>

                    <v-divider
                      v-if="index != selectedMenu.items.length - 1"
                      class="my-1"
                      :key="index + '-a'"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <DialogFiltrosRelatorio
      v-if="dialogFiltrosRelatorio"
      :dialogFiltrosRelatorio.sync="dialogFiltrosRelatorio"
    />
  </v-dialog>
</template>

<script>
import {
  format,
  startOfYear,
  endOfMonth,
  startOfMonth,
  startOfDecade,
  parseISO,
} from "date-fns";

import { mapState, mapGetters } from "vuex";
export default {
  name: "Relatorios",

  components: {
    DialogFiltrosRelatorio: () =>
      import("./components/DialogFiltrosRelatorio.vue"),
  },

  props: {
    dialogRelatorios: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dialogFiltrosRelatorio: false,
      selectedMenu: null,
      menus: [],
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    ...mapState("Usuario", {
      empresa_ativa: (state) => state.usuario.empresa_ativa,
    }),
  },

  methods: {
    openDialogFiltrosRelatorio(item) {
      this.$store.commit("FiltrosRelatorios/SET_SELECTED_ITEM", item);
      this.$store.commit(
        "FiltrosRelatorios/SET__FILTROS_COMPONENTS",
        item.filtros
      );

      this.dialogFiltrosRelatorio = true;
    },
  },

  mounted() {
    this.menus.push(
      {
        title: this.$tc("global.cliente", 2),
        icon: "mdi-clipboard-account-outline",
        active: this.getAccess("RelatorioClientes", "visualizar"),
        items: [
          {
            title: this.$tc("global.extratosdeClientes"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Cliente",
                required: true,
              },
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
            ],
            pdf_path: "clientes/ExtratosDeClientesPdf",
          },
          {
            title: this.$tc("global.listaClientes"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
            ],
            pdf_path: "clientes/ListaDeClientesPdf",
          },
          {
            title: "Contas de Clientes",
            active: true,
            type: "single",
            filtros: [
              {
                text: "Cliente",
                required: true,
              },

              {
                text: "Status",
                required: true,
                options: [
                  {
                    text: this.$tc("global.aberto"),
                    value: 1,
                    color: "red lighten-1",
                  },
                  {
                    text: this.$tc("global.pago"),
                    value: 2,
                    color: "grey lighten-1",
                  },
                  {
                    text: this.$tc("global.vencido"),
                    value: 3,
                    color: "yellow lighten-1",
                  },
                  {
                    text: this.$tc("global.todas"),
                    value: 4,
                    color: "green lighten-1",
                  },
                ],
              },
            ],
            pdf_path: "clientes/ContasDeClientesPdf",
          },
        ],
      },
      {
        title: this.$tc("global.fornecedor", 2),
        icon: "mdi-truck-outline",
        active: this.getAccess("RelatorioFornecedores", "visualizar"),
        items: [
          {
            title: this.$tc("global.extratodeFornecedores"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Fornecedor",
                required: true,
              },
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Status",
                required: false,
                default: "A",
                options: [
                  {
                    text: this.$tc("global.cancelada"),
                    value: -1,
                    color: "red lighten-1",
                  },
                  {
                    text: this.$tc("global.rascunho"),
                    value: 0,
                    color: "grey lighten-1",
                  },
                  {
                    text: this.$tc("global.pendentes"),
                    value: 1,
                    color: "yellow lighten-1",
                  },
                  {
                    text: this.$tc("global.finalizado"),
                    value: 2,
                    color: "green lighten-1",
                  },
                ],
              },
            ],
            pdf_path: "fornecedores/ExtratoDeFornecedoresPdf",
          },
          {
            title: this.$tc("global.listaFornecedor"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
            ],
            pdf_path: "fornecedores/ListaDeFornecedoresPdf",
          },
        ],
      },
      {
        title: this.$tc("global.produto", 2),
        icon: "mdi-basket",
        active: this.getAccess("RelatorioProdutos", "visualizar"),
        items: [
          {
            title: this.$t("global.listaProdutos"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "ProdutosAtivos",
                options: [
                  {
                    text: this.$tc("global.ativo", 2),
                    value: 1,
                    color: "red lighten-1",
                  },
                  {
                    text: this.$tc("global.inativo", 2),
                    value: 2,
                    color: "grey lighten-1",
                  },
                ],
              },
            ],
            pdf_path: "produtos/ListaDeProdutosPdf",
          },
          {
            title: this.$t("global.listaProdutosValor"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
            ],
            pdf_path: "produtos/ListaDeProdutosValorPdf",
          },
          {
            title: this.$t("global.inventario"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "DepositoVinculado",
                required: false,
              },
              {
                text: "Categoria",
                required: false,
              },
              {
                text: "Marca",
                required: false,
              },
              {
                text: "ProdutosAtivos",
                options: [
                  {
                    text: this.$tc("global.ativo", 2),
                    value: 1,
                    color: "red lighten-1",
                  },
                  {
                    text: this.$tc("global.inativo", 2),
                    value: 2,
                    color: "grey lighten-1",
                  },
                ],
              },
            ],
            pdf_path: "produtos/InventarioPdf",
          },
        ],
      },
      {
        title: this.$tc("global.venda", 2),
        icon: "mdi-tag-outline",
        active: this.getAccess("RelatorioVendas", "visualizar"),
        items: [
          {
            title: this.$t("global.vendasPrazoRecebidas"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
            ],
            pdf_path: "vendas/VendasPrazoRecebidasPdf",
          },
          {
            title: this.$t("global.vendasporMesVendedor"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Usuario",
                required: true,
              },
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
            ],
            pdf_path: "vendas/VendasporMesVendedorPdf",
          },
          {
            title: this.$t("global.vendasporMes"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
            ],
            pdf_path: "vendas/VendasPorMesPdf",
          },
          {
            title: this.$tc("global.venda", 2),
            active: true,
            type: "single",
            filtros: [
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "Deposito",
                required: false,
              },
              {
                text: "Usuario",
                required: false,
              },
              {
                text: "Marca",
                required: false,
              },
              {
                text: "Categoria",
                required: false,
              },
              {
                text: "GroupVendas",
                required: false,
              },
              {
                text: "Cliente",
                required: false,
              },
            ],
            pdf_path: "vendas/Vendas",
          },
        ],
      },
      {
        title: this.$tc("global.caixa", 2),
        icon: "mdi-cash-multiple",
        active: this.getAccess("RelatorioCaixas", "visualizar"),
        items: [
          {
            title: this.$t("global.saldo"),
            active: true,
            type: "single",
            filtros: [
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "Caixa",
                required: true,
              },
              {
                text: "Moeda",
                required: true,
              },
            ],
            pdf_path: "caixas/CaixasPeriodoPdf",
          },
          {
            title: this.$tc("global.caixa", 2) + " PDV",
            active: true,
            type: "single",
            filtros: [
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "CaixaPdv",
                required: true,
              },
            ],
            pdf_path: "caixas/CaixasPdvPeriodoPdf",
          },
          {
            title: this.$tc("global.caixa", 2) + " Extrato",
            active: true,
            type: "single",
            filtros: [
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "Caixa",
                required: false,
              },
              {
                text: "Moeda",
                required: false,
              },
              {
                text: "Usuario",
                required: false,
              },
              {
                text: "FormaPagamento",
                required: false,
              },
              {
                text: "PlanoContas",
                required: false,
              },
            ],
            pdf_path: "caixas/CaixasExtrato",
          },
        ],
      },
      {
        title: this.$tc("global.compra", 2),
        icon: "mdi-cart-outline",
        active: this.getAccess("RelatorioCompras", "visualizar"),
        items: [
          {
            title: this.$tc("global.compra", 2),
            active: true,
            type: "single",
            filtros: [
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "Deposito",
                required: false,
              },
              {
                text: "Usuario",
                required: false,
              },
              {
                text: "Marca",
                required: false,
              },
              {
                text: "Categoria",
                required: false,
              },
              {
                text: "GroupCompras",
                required: false,
              },
            ],
            pdf_path: "compras/Compras",
          },
        ],
      },
      {
        title: this.$tc("global.contasReceber", 2),
        icon: "mdi-account-cash-outline",
        active: this.getAccess("RelatorioContasReceber", "visualizar"),
        items: [
          {
            title: this.$tc("global.contasReceber", 2),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Cliente",
                required: false,
              },
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "Status",
                required: false,
                options: [
                  {
                    text: this.$tc("global.avencer"),
                    value: "aberto",
                  },
                  {
                    text: this.$tc("global.vencido", 2),
                    value: "vencidas",
                  },
                  {
                    text: this.$tc("global.vencendoNoDia"),
                    value: "hoje",
                  },
                  {
                    text: this.$tc("global.paga", 2),
                    value: "pagas",
                  },
                ],
              },
              {
                text: "GroupContasReceber",
                required: false,
              },
            ],
            pdf_path: "contasreceber/ContasReceber",
          },
        ],
      },
      {
        title: this.$tc("global.contasPagar", 2),
        icon: "mdi-cash-minus",
        active: this.getAccess("RelatorioContasPagar", "visualizar"),
        items: [
          {
            title: this.$tc("global.contasPagar", 2),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Usuario",
                required: false,
              },
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "Status",
                required: false,
                options: [
                  {
                    text: this.$tc("global.avencer"),
                    value: "aberto",
                  },
                  {
                    text: this.$tc("global.vencido", 2),
                    value: "vencidas",
                  },
                  {
                    text: this.$tc("global.vencendoNoDia"),
                    value: "hoje",
                  },
                  {
                    text: this.$tc("global.paga", 2),
                    value: "pagas",
                  },
                ],
              },
              {
                text: "GroupContasPagar",
                required: false,
              },
            ],
            pdf_path: "contaspagar/ContasPagar",
          },
        ],
      },
      {
        title: this.$tc("global.cobrancaRealizada", 2),
        icon: "mdi-cash-check",
        active: this.getAccess("RelatorioCobrancasRealizadas", "visualizar"),
        items: [
          {
            title: this.$tc("global.cobrancaRealizada", 2),
            active: true,
            type: "single",
            filtros: [
              {
                text: "Usuario",
                required: false,
              },
              {
                text: "DataFrom",
                required: true,
                default: format(startOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "DataTo",
                required: true,
                default: format(endOfMonth(new Date()), "yyyy-MM-dd"),
              },
              {
                text: "Empresa",
                required: true,
                disabled: true,
                default: this.empresa_ativa,
              },
              {
                text: "Cliente",
                required: false,
              },
              {
                text: "Caixa",
                required: false,
              },
              {
                text: "FormaPagamento",
                required: false,
              },
            ],
            pdf_path: "cobrancas_realizadas/CobrancasRealizadas",
          },
        ],
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.box-esquerda {
  // background-color: #f5f5f5;
  height: 400px;
  max-height: 400px;
  overflow: auto;
}
.box-direita {
  height: 400px;
  max-height: 400px;
  overflow: auto;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 30px !important;
}
</style>
